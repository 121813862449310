<template>
  <div>
    <!--Breadcrumb button-->
    <breadcrumb-button>
      <template #beforeDropdown>
        <b-button variant="success" class="btn-icon" v-b-tooltip v-bind:title="$t('operations.add')" @click="onUserSelected(null)">
          <i class="fa-solid fa-user-plus"></i>
        </b-button>
      </template>
      <template #items>
        <b-dropdown-item @click="openColumnsModal">
          <feather-icon
              icon="CheckSquareIcon"
              size="16"
          />
          <span class="align-middle ml-50">{{ $t('columns.self') }}</span>
        </b-dropdown-item>
      </template>
    </breadcrumb-button>
    <!--Filters-->
    <filter-card  v-model="filter" v-bind:fields="filterFields" search/>
    <!--Table Card-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{$t("menu.users")}}</h5>
      </b-card-header>
      <b-card-body>
        <users-table ref="userTable" v-bind:filters="filter" v-bind:columns="columns" :has-selection="false" @selected="onUserSelected" :lazy="true"/>
      </b-card-body>
    </b-card>
    <!--Modal-->
    <columns-modal ref="columns-modal" column-key="users" v-model="columns"
                   v-bind:columns-names="columnsNames" />
    <user-modal ref="user-modal" @refresh="refreshTable" />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import BreadcrumbButton from '@/components/widget/breadcrumbButton'
import UserSearchRequest from '@/model/user/UserSearchRequest'
import ColumnsModal from '@/components/modal/columnsModal'
import User from '@/model/user/User'
import mixinPermissions from '@/components/mixin/mixinPermissions'
import mixinBase from '@/components/mixin/mixinBase'
import UserModal from '@/components/modal/userModal'
import FilterCard from '@/components/widget/FilterCard'
import UserFilterFields from '@/model/filter/UserFilterFields'
import UsersTable from '@/components/tables/UsersTable'

export default {
  name: 'UsersView',
  components: {UsersTable, FilterCard, UserModal, ColumnsModal, BreadcrumbButton },
  mixins: [mixinBase,mixinPermissions],
  data() {
    return {
      columnsNames: Object.keys(User()),
      filter: UserSearchRequest(),
      filterLoaded: false,
      columns: [],
      filterFields: null,
    }
  },
  async created() {
    await this.refreshData()
    this.filterFields = UserFilterFields();
  },
  computed: {
    ...mapGetters('data', ['allOrganizations']),
  },
  methods: {
    ...mapActions('users', ['refreshData']),
    openColumnsModal() {
      this.$refs['columns-modal'].showModal()
    },
    onUserSelected(selectedId) {
      // console.log("onUserSelected", selectedId)
      this.$refs['user-modal'].showModal(selectedId)
    },
    refreshTable() {
      this.$refs['userTable'].refresh()
    },
  }
}
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 10px;
}
</style>
