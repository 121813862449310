import { render, staticRenderFns } from "./UserEditDetails.vue?vue&type=template&id=281c60f7&scoped=true&"
import script from "./UserEditDetails.vue?vue&type=script&lang=js&"
export * from "./UserEditDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "281c60f7",
  null
  
)

export default component.exports