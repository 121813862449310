export default function () {
  return [
    { key: 'firstName' }, { key: 'lastName' }, { key: 'phone' }, { key: 'email' },
    {
      key: 'buIds',
      label: 'businessUnit',
      type: 'bu',
      props: {
        clearable: true,
        multiple: true
      }
    },
    {
      key: 'orgIds',
      type: 'org',
      props: {
        clearable: true,
        multiple: true
      }
    }

  ]
}
