<template>
  <b-modal no-fade v-model="dialog.show" size="xl"  :id="`modal-user-${uniqueId}`"
           :hide-footer="true" centered @close="onDialogClose"
           no-close-on-backdrop
  >
    <template #modal-title>
     <span v-if="loading !== true && error == null && user != null">
     {{user.id}} -   {{ user.firstName }}  {{ user.lastName }}
     </span>
    </template>
    <div style="min-height: 600px">
      <div v-if="loading !== true && error == null">
        <user-edit-details v-bind:new-user="isNewUser" v-model="user" @saveCompleted="sendRefresh" />
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else-if="loading !== true && error != null">
        <b-alert show variant="danger" >
          <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
          {{ error }}
        </b-alert>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
        <b-spinner>{{$t('operations.loading')}}</b-spinner>
      </div>
    </div>
  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import { mapActions } from 'vuex'
import UserEditDetails from '@/components/partial/users/UserEditDetails'
import User from '@/model/user/User'

export default {
  name: 'userModal',
  components: { UserEditDetails },
  mixins: [mixinBase, mixinNotifications],
  data: () => ({
    dialog: {
      show: false,
    },
    uniqueId: 0,
    loading: true,
    error: null,
    id: null,
    isNewUser: false,
    user: {}
  }),
  watch: {
    queryParams:{
      handler(){
        if(this.queryParams.userId == null) {
          this.closeModal()
          return
        }
        if (this.queryParams.userId !== this.id) {
          this.showModal(this.queryParams.userId)
        }
      },
      deep: true
    }
  },
  created() {
    this.uniqueId = this._uid
    if(this.queryParams.userId != null) {
      this.showModal(this.queryParams.userId)
    }
  },
  methods: {
    ...mapActions('users', ['getUserById']),
    showModal(id) {
      this.id = id
      this.loading = true
      this.dialog.show = true
      this.dialog.tab = 0
      this.isNewUser = this.id == null || this.id === 0;
      if(!this.isNewUser) {
        this.refreshData()
      }else {
        this.user = User()
        this.error = null
        this.loading = false
      }
      this.pushState({userId: id, tab: 0, })
    },
    closeModal(){
      this.dialog.show = false;
      this.user = {}
      this.onDialogClose()
    },
    sendRefresh(){
      this.closeModal()
      this.$emit('refresh')
    },
    onDialogClose(){
      this.id = null;
      this.pushState({userId: null, tab: null, })
    },
    refreshData() {
      let $this = this;
      this.getUserById(this.id)
          .then(user => {
            $this.user = user;
            $this.error = null
            $this.loading = false
          }, err => {
            $this.error = $this.getErrorDescription(err)
            $this.loading = false
          })
    },
  }
}
</script>

<style scoped>

</style>
