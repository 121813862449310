<template>
  <div v-bind:key="refreshKey">
    <slot name="table-top-actions" v-bind:selectedIds="selectedIds"></slot>
    <b-table responsive v-bind:items="rows" v-bind:fields="fields" no-local-sorting
             v-bind:sort-by.sync="sort.field" v-bind:sort-desc.sync="sortIsDesc"
             striped hover @row-clicked="onRowClicked" v-bind:busy="loading"
             @sort-changed="onSortChanged"
             style="white-space: nowrap; padding-bottom: 30px"
    >
      <template #head(selection)="data">
        <b-form-checkbox v-bind:indeterminate="isIndeterminate(selected)" v-model="selected.all"
                         style="margin-top: 7px"
                         @input="val=>toggleAllRow(val)" inline
        ></b-form-checkbox>
      </template>
      <template #cell(selection)="data">
        <b-form-checkbox v-model="selectedIds[data.item.id]" inline @input="val=>toggleRow(data.item, val)"
        ></b-form-checkbox>
      </template>
      <template #cell(orgIds)="data">
        <b-badge v-for="org in data.value" class="mr-1" variant="primary">{{ getOrganizationById(org).name }}</b-badge>
      </template>
      <template #cell(roleId)="data">
        <b-badge variant="primary">{{ roleById(data.value).name }}</b-badge>
      </template>
      <template #cell(businessUnits)="data">
        <b-badge v-for="buid in data.value" class="mr-1" variant="primary">{{ getBusinessUnitById(buid).name }}
        </b-badge>
      </template>
      <template #cell(creationtime)="data">
        {{
          $moment(data.value)
              .format('DD.MM.YYYY HH:mm')
        }}
      </template>
      <template #cell(avatar)="data">

        <b-avatar
            id="popover-avatar"
            size="30"
            variant="light-primary"
            :src="require(`@/assets/images/avatars/${data.value || '1'}.png`)"
        />
      </template>
      <template #cell(active)="data">
        <div>
          <span v-if="data.value" class="icon is-success">
      <i class="fas fa-check" style="color:green;"></i>
    </span>

          <span v-else class="icon is-danger">
      <i class="fas fa-times" style="color:red;"></i>
    </span>
        </div>
      </template>

    </b-table>
    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.page"
            v-bind:total-rows="pagination.total"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size" @change="refresh">
          <b-select-option v-bind:value="10">10</b-select-option>
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
        </b-select>
        /{{ pagination.total }} {{ $t('columns.counterNames.users') }}

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';

export default {
  name: 'UsersTable',
  mixins: [mixinBase, mixinTable, mixinNotifications],
  data: () => ({
    rows: [],
    selected: { all: false },
  }),
  props: {
    filters: {
      default: () => ({})
    },
    columns: {
      default: () => ([])
    },
    hasSelection: {
      default: () => false
    },
    lazy: {
      default: () => false
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.refresh();
      }
    }
  },
  created() {
    this.setPagination(1, 0, 10);
    if (!this.lazy) {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters('data', ['getOrganizationById', 'getBusinessUnitById']),
    ...mapGetters('users', ['roleById']),

    fields() {
      let $this = this;
      let fields = (this.columns || []).map(column => {
        return {
          key: column.key,
          label: $this.$t(`columns.${column.key}`),
          sortable: true,
          active: column.enable,
        };
      });
      if (this.hasSelection) {
        fields.unshift({
          key: 'selection',
          label: '',
          sortable: false,
          active: true,
        });
      }
      return fields.filter(f => f.active === true);
    },
    selectedIds() {
      this.refreshKey; // Little hack to recompile
      // console.log("selectedIds()",this.selected)
      return Object.keys(this.selected)
          .filter(k => k !== 'all')
          .reduce((ans, id) => Object.assign(ans, { [id]: true }), {});
    },
  },
  methods: {
    ...mapActions('users', ['getAllUsers']),
    async refresh() {
      let $this = this;
      let loadingToken = this.setLoading();
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'lastUpdate';
      }
      // console.log("refresh()", {  pagination: this.pagination, sort})
      const result = await this.getAllUsers(
          {
            pagination: this.pagination,
            sort,
            filter: this.filters,
          }
      )
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.users') }), $this.getErrorDescription(err));
            $this.setLoaded(loadingToken);
          });
      this.rows = result.data;
      this.setMetaDataFromResult(result);
      this.setLoaded(loadingToken);
    }
  }
};
</script>

<style scoped>

</style>
