<template>
  <div>
    <validation-observer ref="refFormObserver" v-slot="{ validate }">
      <b-form @submit.prevent="handleSubmit(validate)">
        <b-row>
          <b-col cols="12">
            <h4 class="text-primary text-center text-uppercase">
              <i class="fa-solid fa-user"></i>
              {{ $t('tabs.users.details') }}
            </h4>
          </b-col>
          <b-row no-gutters style="width: 100%">
            <b-col cols="3" class="text-center">
              <b-avatar
                  id="popover-avatar"
                  size="100"
                  variant="light-primary"
                  :src="require(`@/assets/images/avatars/${user.avatar || '1'}.png`)"
              />
            </b-col>
            <b-col cols="9">
              <b-row class="m-0 p-0" style="width: 100%">
                <b-col cols="4">
                    <label>{{ $t('columns.firstName') }}</label>
                    <b-form-input v-model="user.firstName" :state="stateFirstName" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
                </b-col>
                <b-col cols="4">
                  <label>{{ $t('columns.lastName') }}</label>
                    <b-form-input v-model="user.lastName" :state="stateLastName" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
                </b-col>
                <b-col cols="4">
                  <label>{{ $t('columns.country') }}</label>
                  <countries-select-box v-model="user.country"
                                        :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"
                  />
                </b-col>
                <b-col cols="4">
                  <label>{{ $t('columns.phone') }}</label>
                  <b-input-group>
                    <b-form-input v-model="user.phone" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
                    <b-input-group-append>
                      <a
                          class="btn btn-outline-primary" target="_blank"
                          v-b-tooltip :title="$t('operations.call')"
                      >
                        <i class="fa-solid fa-phone-volume text-primary"></i>
                      </a>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col cols="4">
                  <label>{{ $t('columns.internPhone') }}</label>
                  <b-input-group>
                    <b-form-input v-model="user.internPhone"
                                  :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"
                    />
                    <b-input-group-append>
                      <a
                          class="btn btn-outline-primary" target="_blank"
                          v-b-tooltip :title="$t('operations.call')"
                      >
                        <i class="fa-solid fa-phone-volume text-primary"></i>
                      </a>
                    </b-input-group-append>
                  </b-input-group>
                </b-col>
                <b-col cols="4">
                  <label>{{ $t('columns.email') }}</label>
                    <b-input-group>
                      <b-form-input v-model="user.email" :state="stateEmail" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
                      <b-input-group-append>
                        <a
                            class="btn btn-outline-primary" target="_blank"
                            v-b-tooltip :title="$t('operations.sendMail')"
                        >
                          <i class="fa-solid fa-envelope"></i>
                        </a>
                      </b-input-group-append>
                    </b-input-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-col cols="4" xl="3">
            <label>{{ $t('columns.address') }}</label>
            <b-form-input v-model="user.address" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
          </b-col>
          <b-col cols="4" xl="3">
            <label>{{ $t('columns.zip') }}</label>
            <b-form-input v-model="user.zip" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
          </b-col>
          <b-col cols="4" xl="3">
            <label>{{ $t('columns.city') }}</label>
            <b-form-input v-model="user.city" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
          </b-col>
          <b-col cols="4" xl="3">
            <label>{{ $t('columns.birthday') }}</label>
            <calendar-picker size="md" v-model="user.birthday" is-range mode="date"/>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <h4 class="text-primary text-center text-uppercase">
              <i class="fa-solid fa-building-circle-exclamation"></i>
              {{ $t('tabs.users.management') }}
            </h4>
          </b-col>
          <b-col cols="4" xl="3">
            <label>{{ $t('columns.creationTime') }}</label>
            <b-form-input v-model="user.creationTime" readonly/>
          </b-col>
          <b-col cols="4" xl="3">
            <label>{{ $t('columns.startWork') }}</label>
            <b-form-input v-model="user.startWork" readonly/>
          </b-col>
          <b-col cols="4" xl="3">
            <label>{{ $t('columns.businessUnits') }}</label>
            <business-unit-select-box v-model="user.businessUnits" multiple
                                      v-bind:selected-organization="user.orgIds"
                                      :disabled="!hasPermission(permissions.CLIENT_UPDATE_BU)"
            />
          </b-col>
          <b-col cols="4" xl="3">
            <label>{{ $t('columns.orgIds') }}</label>
            <organization-select-box v-model="user.orgIds" multiple
                                     :disabled="!hasPermission(permissions.CLIENT_UPDATE_ORG)"
            />
          </b-col>
          <b-col cols="4" xl="3">
            <label>{{ $t('columns.roles') }}</label>
            <v-select
                v-model="user.roleId"
                :options="allRoles"
                label="name"
                :reduce="(role) => role.id"
            />
          </b-col>
          <b-col cols="4" xl="3">
            <validation-provider #default="validationContext" name="Username" rules="required">
              <label>{{ $t('columns.username') }}</label>
              <b-form-input v-model="user.username"
                            :state="getValidationState(validationContext) && stateUsername"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
          <b-col cols="4" xl="3">
            <label>{{ $t('columns.password') }}</label>
            <b-form-input v-model="user.password"/>
          </b-col>
          <b-col cols="4" xl="3">
            <label class="invisible">{{ $t('columns.active') }}</label>
            <b-checkbox switch v-model="user.active">{{ $t('columns.active') }}</b-checkbox>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <div class="text-center mt-2" style="position: absolute;bottom: 10px; width: 100%">
      <b-button block size="md" variant="primary" type="submit" @click="save">{{ $t('operations.save') }}</b-button>
    </div>
    <b-popover target="popover-avatar" triggers="hover" placement="right" size="xl">
      <div style="max-width: 500px">
        <b-row>
          <b-col v-for="index in 24" v-bind:key="index" class="p-1" @click="onAvatarSelected(index)">
            <b-avatar
                class="selectable"
                :class="{'selected': user.avatar === (''+index), [`avatar-${index}`]: true}"
                size="60"
                variant="light-primary"
                :src="require(`@/assets/images/avatars/${index}.png`)"
            />
          </b-col>
        </b-row>
      </div>
    </b-popover>
  </div>
</template>

<script>
import User from '@/model/user/User';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import { mapActions, mapGetters } from 'vuex';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox';
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox';
import CountriesSelectBox from '@/components/widget/select/CountriesSelectBox';
import CalendarPicker from '@/components/widget/picker/calendarPicker';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import mixinValidation from '@/components/mixin/mixinValidation';

export default {
  name: 'UserEditDetails',
  components: {
    ValidationProvider,
    ValidationObserver,
    CalendarPicker,
    CountriesSelectBox,
    BusinessUnitSelectBox,
    OrganizationSelectBox
  },
  mixins: [mixinPermissions, mixinNotifications, mixinValidation],
  data: () => ({
    user: User(),
    mandatoryFieldState: {"stateFirstName": null, "stateLastName": null,
      "stateEmail": null, "stateUsername": null},
  }),
  props: {
    value: {
      required: true,
      default: () => ({})
    },
    newUser: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    value: {
      handler() {
        this.user = Object.assign(User(), this.value);
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('users', ['allRoles']),
    stateFirstName() {
      if(this.user == null || this.user.firstName == null || this.user.firstName.length < 1) {
        this.mandatoryFieldState.stateFirstName = false;
        return false;
      }
      this.mandatoryFieldState.stateFirstName = true;
      return null
    },
    stateLastName() {
      if(this.user === null ||this.user.lastName===null || this.user.lastName.length < 1) {
        this.mandatoryFieldState.stateLastName = false;
        return false;
      }
      this.mandatoryFieldState.stateLastName = true;
      return null
    },
    stateEmail() {
      if(this.user === null ||this.user.email===null ||
          !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test(this.user.email))) {
        this.mandatoryFieldState.stateEmail = false;
        return false;
      }
      this.mandatoryFieldState.stateEmail =true;
      return null;
    },
    stateUsername() {
      if(this.user === null ||this.user.username===null || this.user.username.length < 1) {
        this.mandatoryFieldState.stateUsername = false;
        return false;
      }
      else {
        this.mandatoryFieldState.stateUsername = true;
        return null
      }
    },
  },
  methods: {
    ...mapActions('users', ['updateUser', 'createUser']),
    handleSubmit(validate) {
      validate()
          .then(success => {
            if (success) {
              // Proceed with form submission
              this.save();
            } else {
              // Show validation errors
              console.log('Validation failed.');
            }
          });
    },
    async save() {
      if (this.user.active == null) {
        this.user.active = false;
      }
      let neededField = this.checkMandatory();
      if (this.newUser && neededField) {
        await this.createUserWrapper();
      } else if (neededField) {
          await this.updateUserWrapper();
      }
    },
    checkMandatory(){
      for(let key in this.mandatoryFieldState){
        if (this.mandatoryFieldState[key]=== false) {
          console.log("state is false")
          return false;
        }
      }
      console.log("state is true")
      return true;
    },

    async createUserWrapper() {
      let $this = this;
      await this.createUser({
        user: this.user
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.users') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.createDataSuccess', { system: $this.$t('menu.users') }));

      this.$emit('input', this.user);
      this.$emit('saveCompleted');
    },

    async updateUserWrapper() {
      let $this = this;
      await this.updateUser({
        user: this.user,
        id: this.user.id
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.users') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.users') }));

      this.$emit('input', this.user);
      this.$emit('saveCompleted');
    },
    onAvatarSelected(name) {
      console.log('onAvatarSelected', name);
      this.user.avatar = '' + name;
    }
  }
};
</script>

<style scoped>

</style>
