export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    creationTime: other.creationTime || null,
    firstName: other.firstName || null,
    lastName: other.lastName || null,
    username: other.username || null,
    avatar: other.avatar || null,
    phone: other.phone || null,
    email: other.email || null,
    orgIds: other.orgIds || null,
    birthday: other.birthday || null,
    startWork: other.startWork || null,
    docNum: other.docNum || null,
    docType: other.docType || null,
    docCountry: other.docCountry || null,
    country: other.country || null,
    city: other.city || null,
    address: other.address || null,
    zip: other.zip || null,
    color: other.color || null,
    logoLink: other.logoLink || null,
    lang: other.lang || null,
    token: other.token || null,
    roleId: other.roleId || null,
    internPhone: other.internPhone || null,
    cost: other.cost || null,
    smtpList: other.smtpList || null,
    businessUnits: other.businessUnits || null,
    active: other.active || null,
    password: other.password || null,
    group: other.group || null,
  }
}
